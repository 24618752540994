import * as React from 'react';

import styles from './section.module.css';

interface SectionProps {
  altBackground?: boolean;
  children: React.ReactNode;
}

export const Section = ({ altBackground, children }: SectionProps) => (
  <section
    className={[styles.section, altBackground ? 'alt-background' : undefined]
      .filter((s) => s)
      .join(' ')}
  >
    {children}
  </section>
);
