import * as React from 'react';
import { Link } from 'gatsby';

import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import SEO from '../components/seo';

const Services = () => (
  <Layout>
    <SEO title="Hearing Services We Offer" />

    <Section>
      <Container>
        <h1>Hearing Healthcare Services</h1>
        <p>The perfect balance between human touch and technology.</p>
      </Container>
    </Section>

    <Section>
      <Container>
        <h2>Hearing Evaluation.</h2>
        <p>
          To accurately evaluate your level of hearing loss, a comprehensive
          hearing test is required. We perform a series of tests to help better
          understand all the different aspects of your individual hearing loss.
        </p>

        <Link to="/first-appointment">
          Here is what you can expect during your evaluation.
        </Link>
      </Container>
    </Section>

    <Section altBackground>
      <Container>
        <h2>Hearing Aid Service.</h2>

        <h3>Hearing aid demonstration.</h3>
        <p>
          There are many different levels of hearing loss and not all hearing
          aids are appropriate for your particular level of hearing loss. We
          take the time to help you test different hearing aids, so you know
          which hearing aids are best for you
        </p>

        <h3>Hearing aid sales.</h3>
        <p>
          Your hearing aid is programmed to your loss, which means your hearing
          aid is only as good as the results of your evaluation.
        </p>
        <p>This is where at-home hearing tests just don’t cut it.</p>
        <p>
          At Modern Hearing Solutions, our Wyoming, licensed hearing instrument
          specialist can provide you with the best hearing technology available.
        </p>

        <h3>Repairs on major brands.</h3>
        <p>
          Whether due to wear and tear or because of an accident, your hearing
          aids may require repairs. Bring them in. We make repairs on all major
          brands.
        </p>

        <h3>Batteries.</h3>
        <p>
          We carry hearing aid batteries and will gladly change them out for
          you. If you’re tired of handling the small batteries, we can also
          discuss rechargeable hearing aid options.
        </p>

        <h3>Custom ear impressions.</h3>
        <p>
          If you need customized hearing aids, we’ll take impressions to create
          the perfect fit.
        </p>
      </Container>
    </Section>

    {/* <Section>
      <Container>
        <h2>In-home assistance.</h2>
        <p>
          We know not all who have hearing needs are able to make it into the
          office, so let us come to you. Whether it’s evaluating your hearing
          with our mobile equipment, customizing or re-tuning your hearing aids
          or swapping out batteries, we don’t want your hearing health to go
          untreated.
        </p>
      </Container>
    </Section> */}

    <Section>
      <Container>
        <h2>Tinnitus Management.</h2>

        <p>
          Hearing loss and tinnitus are often connected conditions. If you
          struggle with tinnitus with or without hearing loss, we can help.
        </p>
        <p>
          Many major hearing aid brands now offer built-in tinnitus support.
          Depending on the severity of your tinnitus, we can discuss all the
          different tinnitus support-enabled hearing aids available to you.
        </p>
      </Container>
    </Section>

    <Section altBackground>
      <Container>
        <h2>Insurance.</h2>

        <p>
          Did you know that your hearing devices may be covered entirely by your
          insurance?
        </p>
        <p>
          Did you also know that one small mistake in processing your claim can
          lead to you paying significantly more out of pocket then it should?
        </p>
        <p>
          Well-meaning hearing clinics train their front office staff, with
          little to no experience in insurance billing, to handle their claims
        </p>
        <p>
          At Modern Hearing Solutions, we work with Hearing Healthcare specific
          Insurance Specialists to make sure your claim is filed quickly and
          correctly. Often, resulting in less out-of-pocket for you.
        </p>
      </Container>
    </Section>

    <Section>
      <Container>
        <h2>0% Hearing Aid Financing.</h2>

        <p>
          If you have insurance, we’ll help determine if and how much they are
          willing to pay for hearing aids.
        </p>
        <p>
          If you don’t have insurance and are unable to pay out-of-pocket, we
          can still help. We work with a financing company that offers
          no-interest financing for those who qualify
        </p>
      </Container>
    </Section>

    <Section altBackground>
      <Container>
        <h2>Lifetime Commitment To Your Hearing.</h2>

        <p>
          When you choose to work with Modern Hearing Solutions, you are
          choosing to receive lifetime service from our hearing clinic. We offer
          free annual retesting of your hearing to determine if your hearing
          needs have changed and periodic hearing consultations.
        </p>
      </Container>
    </Section>
  </Layout>
);

export default Services;
